import _ from 'xe-utils';
import BasePage from '../../../../components/table_page';
import Form from '../../components/process_approve/approve.vue';
import CopyHandle from '../../components/copy_handle/copy_handle.vue';

export default {
  extends: BasePage,
  components: {
    Form,
    CopyHandle,
  },
  data() {
    return {};
  },
  created() {
    this.getConfigList('work-flow-backlog');
  },
  methods: {
    beforeGetList() {
      if (_.isEmpty(this.searchFormData)) {
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      const { code, name } = val;
      this.modalConfig.title = name;

      this.formConfig = {
        code,
        row,
      };

      if (code === 'work-flow-deal') {
        this.modalConfig.type = 'EmptyDrawer';
        this.openModal();
      } else if (code === 'circulated') {
        this.formName = 'CopyHandle';
        this.openModal();
      }
    },
  },
};
